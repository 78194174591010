
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DOMForm extends Vue {
  dense = true;
  data = {
    rolPropiedad: "",
    direccionPropiedad: "",
    nroExpediente: ""
  };
  private grabUserInput(data: any, index: any) {
    this.$emit("user-input-changed", data, index);
  }
}


import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

@Component
export default class DOMDESForm extends Mixins(FormValidator) {
  dense = true;
  data = {
    rolPropiedad: "",
    direccionPropiedad: "",
    rutAsistente: ""
  };
  private grabUserInput(data: any, index: any) {
    this.$emit("user-input-changed", data, index);
  }
  private formatThisRut() {
    if (
      this.data.rutAsistente.replace("-", "").replace(/[.-]/g, "").length <= 7
    ) {
      this.data.rutAsistente = this.data.rutAsistente
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{3})(\d{3})(\w{1})$/, "$1.$2-$3");
    } else {
      this.data.rutAsistente = this.data.rutAsistente
        .replace("-", "")
        .replace(/[.-]/g, "")
        .replace(/^(\d{0,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
    }
  }
}

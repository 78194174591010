
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import DOMForm from "@/components/forms/services/dom.vue";
import ZOOForm from "@/components/forms/services/zoo.vue";
import DOMDESForm from "@/components/forms/services/dom-des.vue";
import VueRecaptcha from "vue-recaptcha";
import PreguntasDinamicas from "@/components/forms/PreguntasDinamicas.vue";

interface ticketData {
  street: string;
  street_number: string;
  hint: string;
  message: string;
  presencial: string;
}

interface domData {
  rolPropiedad: string;
  direccionPropiedad: string;
  nroExpediente: string;
}

interface zooData {
  nombreMascota: string;
  especieMascota: string;
  edadMascota: string;
  direccionDueno: string;
}

interface domdesData {
  rolPropiedad: string;
  direccionPropiedad: string;
  rutAsistente: string;
}

interface respuesta {
  id_pregunta: number | null;
  id_formulario: number | null;
  pregunta: string | null;
  respuesta: string | null;
  tipo: number | null;
  obligatorio: number | null;
}

interface dataRespuesta {
  id_formulario: number | null;
  preguntas: Array<respuesta>;
}

@Component({
  components: {
    DOMForm,
    ZOOForm,
    DOMDESForm,
    VueRecaptcha,
    PreguntasDinamicas
  }
})
export default class SendTicketForm extends Mixins(FormValidator) {
  street_loading = false;
  street_search_empty = false;
  check_loading = false;
  disable_button = false;
  dom = false;
  zoo = false;
  domdes = false;
  stateRule: any = false;
  name: any = null;
  age: any = null;
  mensaje = "";

  protected formData: ticketData = {
    street: "",
    street_number: "",
    hint: "",
    message: "",
    presencial: ""
  };

  protected zooData: zooData = {
    nombreMascota: "",
    edadMascota: "",
    especieMascota: "",
    direccionDueno: ""
  };

  protected domData: domData = {
    rolPropiedad: "",
    direccionPropiedad: "",
    nroExpediente: ""
  };

  protected domdesData: domdesData = {
    rolPropiedad: "",
    direccionPropiedad: "",
    rutAsistente: ""
  };

  private options_tipo_asistencia = [
    { label: "Presencial", value: "1" },
    { label: "Online", value: "0" }
  ];

  private formulario: any = null;
  private respuestas: Array<dataRespuesta> = [];
  private data_preguntas: any = [];
  id_formulario = 0;

  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }

  private onVerify(response: any) {
    this.sendTicketAttempt();
  }

  private onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private checkVerify() {
    this.check_loading = true;
    this.$axios
      .get("personas/checkVerified", {
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.$store.dispatch("setEmailVerified", response.data);
      })
      .catch(error => {
        switch (error.response.data.error.message) {
          case "auth/internal-error":
            this.$q.notify({
              message: "Ha ocurrido un error interno",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          case "auth/invalid-uid":
            this.$q.notify({
              message: "Id inválida",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          case "auth/missing-uid":
            this.$q.notify({
              message: "La operación no",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          case "auth/operation-not-allowed":
            this.$q.notify({
              message: "Operación no permitida",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
          default:
            this.$q.notify({
              message: "Error desconocido",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            break;
        }
      })
      .finally(() => {
        this.check_loading = false;
      });
  }

  private getServiceLabel() {
    // todo incorporar a modelo de datos.
    let checkDOM = new RegExp("^Reserva de Hora Recepciones*");
    let checkDOM2 = new RegExp("^Reserva de Hora Permisos*");
    let checkZOO = new RegExp("^Agenda Zoonosis*");
    let checkDOMDes = new RegExp("^Agenda para desarchivo*");
    let serviceName = this.$store.state.ticket.service.name;
    if (checkDOM.test(serviceName) || checkDOM2.test(serviceName))
      this.dom = true;
    else if (checkZOO.test(serviceName)) this.zoo = true;
    else if (checkDOMDes.test(serviceName)) this.domdes = true;
  }

  private updateMessageDom(data: any, index: any) {
    switch (index) {
      case 0:
        this.domData.rolPropiedad = data;
        break;
      case 1:
        this.domData.direccionPropiedad = data;
        break;
      case 2:
        this.domData.nroExpediente = data;
        break;
    }
  }

  private updateMessageDomDes(data: any, index: any) {
    switch (index) {
      case 0:
        this.domdesData.rolPropiedad = data;
        break;
      case 1:
        this.domdesData.direccionPropiedad = data;
        break;
      case 2:
        this.domdesData.rutAsistente = data;
        break;
    }
  }

  private updateMessageZoo(data: any, index: any) {
    switch (index) {
      case 0:
        this.zooData.nombreMascota = data;
        break;
      case 1:
        this.zooData.especieMascota = data;
        break;
      case 2:
        this.zooData.edadMascota = data;
        break;
      case 3:
        this.zooData.direccionDueno = data;
        break;
    }
  }

  private sendTicketAttempt() {
    this.disable_button = true;

    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.$q.notify({
        message: this.$t("notification.check_captcha") as string,
        color: "principal",
        position: "top",
        timeout: 5000
      });
      this.$q.loading.hide();
      return (this.disable_button = false);
    } else if (this.formData.presencial.length == 0) {
      if (this.$store.state.ticket.service.reservation_type == 2) {
        this.stateRule = true;
        this.$q.notify({
          message: "Se debe rellenar el campo Tipo de asistencia",
          color: "principal",
          position: "top",
          timeout: 7500
        });
        this.disable_button = false;
        return;
      }
    } else if (this.formData.message.length == 0) {
      this.stateRule = true;
      this.$q.notify({
        message: "Se debe rellenar el campo mensaje",
        color: "principal",
        position: "top",
        timeout: 7500
      });
      this.disable_button = false;
      return;
    }

    if (this.dom) {
      if (this.domData.direccionPropiedad)
        this.formData.message =
          "Direccion Propiedad: " +
          this.domData.direccionPropiedad +
          "\n" +
          this.formData.message;
      if (this.domData.rolPropiedad)
        this.formData.message =
          "Rol Propiedad: " +
          this.domData.rolPropiedad +
          "\n" +
          this.formData.message;
      if (this.domData.nroExpediente)
        this.formData.message =
          "Número Expendiente: " +
          this.domData.nroExpediente +
          "\n" +
          this.formData.message;
    } else if (this.zoo) {
      if (
        !this.zooData.especieMascota ||
        !this.zooData.nombreMascota ||
        !this.zooData.direccionDueno
      ) {
        this.disable_button = false;
        return;
      } else {
        this.formData.message = [
          "Edad de Mascota: ",
          this.zooData.edadMascota,
          "Nombre de Mascota: ",
          this.zooData.nombreMascota,
          "Especie de Mascota: ",
          this.zooData.especieMascota,
          "Dirección del Dueño: ",
          this.zooData.direccionDueno,
          this.formData.message
        ].join("\n");
      }
    } else if (this.domdes) {
      if (
        !this.domdesData.rutAsistente ||
        !this.domdesData.rolPropiedad ||
        !this.domdesData.direccionPropiedad
      ) {
        this.disable_button = false;
        return;
      } else {
        this.formData.message = [
          "RUT asistente presencial: ",
          this.domdesData.rutAsistente,
          "Rol Propiedad: ",
          this.domdesData.rolPropiedad,
          "Dirección Propiedad: ",
          this.domdesData.direccionPropiedad,
          this.formData.message
        ].join("\n");
      }
    }

    let flag = true;
    if (this.data_preguntas && this.data_preguntas.length > 0) {
      for (let i = 0; i < this.data_preguntas.length; i++) {
        if (
          this.data_preguntas[i].obligatorio == 1 &&
          this.data_preguntas[i].respuesta === ""
        ) {
          flag = false;
        } else if (
          this.data_preguntas[i].obligatorio == 1 &&
          this.data_preguntas[i].tipo == 4
        ) {
          if (!/^\d{2}-\d{2}-\d{4}$/.test(this.data_preguntas[i].respuesta)) {
            this.$q.notify({
              message:
                "Fecha incompleta. Por favor, ingrese una fecha en el formato dd-mm-aaaa",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            this.disable_button = false;
            this.stateRule = true;
            return;
          } else if (!this.checkDate(this.data_preguntas[i].respuesta)) {
            this.$q.notify({
              message: "Fecha inválida. Por favor, escriba una fecha válida",
              color: "principal",
              position: "top",
              timeout: 5000
            });
            this.disable_button = false;
            this.stateRule = true;
            return;
          }
        }
      }
    }

    if (flag) {
      var presencial = 1;
      switch (this.$store.state.ticket.service.reservation_type) {
        case 1:
          presencial = 1;
          break;
        case 2:
          presencial = Number(this.formData.presencial);
          break;
        case 3:
          presencial = 0;
          break;
        default:
          presencial = 1;
          break;
      }
      this.$axios
        .post(
          "tickets/pedir/hora",
          {
            id_servicio: Number(
              this.$store.state.ticket.date_ticket.service_id
            ),
            rut: this.$store.state.logged
              ? this.$store.state.user.rut
              : this.$store.state.ticket.guest_id,
            tipo_documento: this.$store.state.logged
              ? this.$store.state.user.document_type
              : this.$store.state.ticket.guest_document_type,
            id_horario: Number(this.$store.state.ticket.date_ticket.id),
            message: this.formData.message,
            respuestas: this.data_preguntas,
            presencial: presencial
          },
          {
            headers: {
              Authorization: this.$store.state.user.auth_token,
              Captcha: captcha ? captcha : null
            }
          }
        )
        .then(response => {
          let ticket = {
            id: response.data.id_ticket,
            message: response.data.mensaje,
            date: response.data.fecha,
            step: 4,
            adjuntos: response.data.adjunto ? response.data.adjunto : null,
            qr: response.data.qr_code,
            service_name: response.data.servicio,
            motivo_reserva: this.formData.message,
            correlativo: response.data.correlativo
          };
          // this.disable_button = false;
          this.$store.dispatch("submitRequestFinished", ticket);
        })
        .catch(error => {
          this.disable_button = false;
          let message = "";
          switch (error.response.data.error.message) {
            case "empty_id_horario":
              message =
                "El horario ya no se encuentra disponible. Intente \
              nuevamente en otro horario disponible.";
              break;
            case "reserva_usada":
            case "persona_ya_reservo":
              message =
                "El servicio solicitado ya registra una reserva hecha con el rut ingresado";
              break;
            case "empty_captcha":
            case "invalid_captcha":
              message = "Ha ocurrido un error validando su captcha";
              break;
            default:
              message =
                "Ha ocurrido un error al generar la reserva. \
              Intente nuevamente en otro horario disponible.";
          }
          this.$q.notify({
            message: message,
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
    } else {
      this.disable_button = false;
      this.stateRule = true;
      this.$q.notify({
        message: "Rellene los campos obligatorios (*)",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }
  }

  private getFormulario() {
    this.$q.loading.show();
    this.$axios
      .get("servicios/" + Number(this.$store.state.ticket.service.id), {
        params: {
          filter: {
            fields: ["id", "requiere_documento", "mensaje_documento"],
            include: [
              {
                relation: "formulario",
                scope: {
                  fields: ["id", "id_servicio"],
                  include: [
                    {
                      relation: "formulario_pregunta",
                      scope: {
                        where: { estado: 1 },
                        order: ["prioridad ASC"],
                        fields: [
                          "id",
                          "id_formulario",
                          "pregunta",
                          "tipo",
                          "obligatorio",
                          "icon",
                          "label",
                          "prioridad",
                          "estado"
                        ],
                        include: [
                          {
                            relation: "formulario_pregunta_opcions",
                            scope: {
                              fields: ["id", "id_formulario_pregunta", "label"]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      })
      /*this.$axios
      .get("formularios", {
        params: {
          filter: {
            where: {
              id_servicio: Number(this.$store.state.ticket.service.id),
            },
            include: ["formulario_pregunta"],
          },
        },
      })*/
      .then(response => {
        this.$q.loading.hide();
        if (response.data.formulario && response.data.formulario.length > 0) {
          for (let i = 0; i < response.data.formulario.length; i++) {
            const pregunta = response.data.formulario[i];
            if (pregunta.formulario_pregunta) {
              for (let j = 0; j < pregunta.formulario_pregunta.length; j++) {
                var tempRespuesta = "";
                if (pregunta.formulario_pregunta[j].tipo == 1) {
                  tempRespuesta = "No";
                } else {
                  tempRespuesta = "";
                }
                var opciones = [];
                if (
                  pregunta.formulario_pregunta[j].formulario_pregunta_opcions
                ) {
                  for (
                    let l = 0;
                    l <
                    pregunta.formulario_pregunta[j].formulario_pregunta_opcions
                      .length;
                    l++
                  ) {
                    opciones.push({
                      value:
                        pregunta.formulario_pregunta[j]
                          .formulario_pregunta_opcions[l].id,
                      label:
                        pregunta.formulario_pregunta[j]
                          .formulario_pregunta_opcions[l].label
                    });
                  }
                }
                this.data_preguntas.push({
                  id_pregunta: pregunta.formulario_pregunta[j].id,
                  id_formulario: pregunta.formulario_pregunta[j].id_formulario,
                  pregunta: pregunta.formulario_pregunta[j].pregunta,
                  respuesta: tempRespuesta,
                  opciones: opciones,
                  tipo: pregunta.formulario_pregunta[j].tipo,
                  obligatorio: pregunta.formulario_pregunta[j].obligatorio,
                  icon: pregunta.formulario_pregunta[j].icon,
                  label: pregunta.formulario_pregunta[j].label
                });
              }
            }
          }
        }
      })
      .catch(error => {
        this.$q.loading.hide();
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private abortFilterFn() {}

  private getServicioMeta() {
    this.$axios
      .get("servicio-metas", {
        params: {
          filter: {
            where: {
              and: [
                {
                  id_servicio: Number(this.$store.state.ticket.service.id)
                },
                {
                  meta_key: "reservation_message"
                }
              ]
            }
          }
        }
      })
      .then(response => {
        if (response.data.length == 0) this.mensaje = "";
        else this.mensaje = response.data[0].meta_value;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private mounted() {
    if (this.$store.state.logged) this.checkVerify();
    this.getServicioMeta();
    this.getServiceLabel();
    this.getFormulario();
  }
}


import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

@Component
export default class ZOOForm extends Mixins(FormValidator) {
  dense = true;
  data = {
    nombreMascota: "",
    especieMascota: "",
    edadMascota: "",
    direccionDueno: ""
  };
  private grabUserInput(data: any, index: any) {
    this.$emit("user-input-changed", data, index);
  }
}
